export const state = () => ({
    banner: false,
    addOnProductsInfo: [],
});

export const mutations = {
    set_banner(state, banner) {
        state.banner = banner;
    },
    set_addOnProductsInfo(state, addOnProductInfo) {
        const addOnProductType = addOnProductInfo?.type;
        if (addOnProductType) {
            state.addOnProductsInfo = [...state.addOnProductsInfo.filter((item) => item.type !== addOnProductType), addOnProductInfo];
        }
    },
};

export const actions = {
    async fetchBanner({ commit, state }) {
        try {
            if (!state.banner && !state.banner?.sys?.id) {
                const page = await this.$axios.$get('/contentful/fi/globalBanner');

                if (page.total) {
                    commit('set_banner', page.items[0]);
                }
            }

            return state.banner;
        } catch (e) {
            commit('set_banner', false);
        }
    },
    async fetchAddOnProductInfo({ commit, state }, { addOnProductType }) {
        try {
            const type = addOnProductType || 'hupsisturva-modal';
            const page = await this.$axios.$get(`/contentful/fi/addOnProductInfo?type=${type}`);

            if (page.total) {
                commit('set_addOnProductsInfo', { ...page.items[0], type });
            }

            return state.addOnProductsInfo;
        } catch (e) {
            console.error(e);
        }
    },
};

export const getters = {
    banner: (state) => state.banner,
    addOnProductsInfo: (state) => (type) => {
        return state.addOnProductsInfo.find((item) => item.type === type);
    },
};
