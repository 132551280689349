//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import addOnProductCms from '~/mixins/addOnProductCms';

export default {
    mixins: [addOnProductCms],
    props: {
        product: {
            required: true,
        },
    },
    data() {
        return {
            addOnProduct: this.product.linked_insurance_product,
            btnStyle: 'text-xs px-3 py-[6px] font-bold rounded-full',
            visible: true,
        };
    },
    computed: {
        isOnCartPage() {
            return this.$route.path === '/cart';
        },
        insurancePrice() {
            return this.$root.$options.filters.currencyValue(this.addOnProduct?.price);
        },
    },
    methods: {
        hide() {
            this.visible = false;
        },
        async activateInsurance() {
            const payload = {
                sku: this.product.linked_insurance_product.sku, //hupsisturva product sku
                insured_product_sku: this.product.sku, //parent product sku
                quantity: 1,
            };

            const { cart, user_errors } = await this.$store.dispatch('cart/activateInsurance', {
                items: [payload],
            });
        },
    },
};
