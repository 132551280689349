//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import query from '@/queries/quicksearch.graphql';
import categoryQuery from '@/queries/quicksearchCategory.graphql';
import ClickOutside from 'vue-click-outside';

export default {
    directives: {
        ClickOutside,
    },
    props: ['max', 'label'],
    data() {
        return {
            loading: false,
            timer: null,
            productSearchMinLength: 1,
            categorySearchMinLength: null,
            categorySearchMaxLength: null,
            debounceTimeout: 500,
            search: '',
            products: [],
            categories: [],
            articles: [],
        };
    },
    watch: {
        $route() {
            this.products = [];
            this.search = '';
        },
    },
    methods: {
        processInput() {
            if (this.timer !== null) {
                clearTimeout(this.timer);
            }
            this.timer = setTimeout(() => this.processSearch(), this.debounceTimeout);
        },
        async processSearch() {
            this.timer = null;
            this.products = [];
            this.categories = [];
            await this.loadResults();
        },
        async loadResults() {
            this.loading = true;
            this.products = [];
            this.categories = [];
            try {
                if (this.categorySearchMinLength === null || this.categorySearchMaxLength === null) {
                    const defaultMin = 3;
                    const defaultMax = 128;
                    const {
                        data: { storeConfig },
                    } = await this.$axios.$get(`/config`);
                    this.categorySearchMinLength = parseInt(storeConfig.catalog_search_min_query_length) || defaultMin;
                    this.categorySearchMaxLength = parseInt(storeConfig.catalog_search_max_query_length) || defaultMax;
                }
                if (this.search.length >= this.productSearchMinLength) {
                    const {
                        data: {
                            products: { items },
                        },
                    } = await this.$apollo.query({
                        query,
                        variables: { input: this.search, pageSize: this.max },
                        queryDeduplication: false,
                    });
                    this.products = items;
                    if (this.search.length >= this.categorySearchMinLength && this.search.length <= this.categorySearchMaxLength) {
                        const {
                            data: { categoryList },
                        } = await this.$apollo.query({
                            query: categoryQuery,
                            variables: { input: this.search, pageSize: this.max },
                            queryDeduplication: false,
                        });
                        this.categories = categoryList.slice(0, 6);
                    }
                }
            } finally {
                this.loading = false;
            }
        },
        onSubmit() {
            this.$root.$emit('quicksearch', this.search);
            this.$router.push(this.localeRoute({ name: 'search', query: { q: this.search } }));
        },
        onClose(event) {
            if (!event.target.dataset.notclose) {
                this.products = [];
                this.search = '';
            }
        },
        onCloseEsc() {
            this.products = [];
            this.search = '';
        },
    },
};
