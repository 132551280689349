export const state = () => ({
    lastSelectedAttribute: null,
});

export const mutations = {
    setLastSelectedAttribute(state, attribute) {
        state.lastSelectedAttribute = attribute;
    },
};

export const actions = {
    updateLastSelectedAttribute({ commit }, attribute) {
        commit('setLastSelectedAttribute', attribute);
    },
};

export const getters = {
    getLastSelectedAttribute(state) {
        return state.lastSelectedAttribute;
    },
};
