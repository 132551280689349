import addToCartMutation from '@/queries/mutations/addProduct.gql.js';
import updateCartMutation from '@/queries/mutations/updateCart.gql.js';
import mutation from '@/queries/mutations/removeItemFromCart.gql.js';
import createEmptyCart from '@/queries/mutations/createEmptyCart.graphql';
import getCart from '@/queries/getCart.gql.js';
import getCustomerCart from '@/queries/getCustomerCart.gql.js';
import setGuestEmailOnCart from '@/queries/mutations/setGuestEmailOnCart.gql.js';
import setShippingAddressOnCart from '@/queries/mutations/setShippingAddressOnCart.gql.js';
import setShippingMethodsOnCart from '@/queries/mutations/setShippingMethodsOnCart.gql.js';
import setBillingAddressOnCart from '@/queries/mutations/setBillingAddressOnCart.gql.js';
import setAdditionalDataOnCart from '@/queries/mutations/setAdditionalDataOnCart.gql.js';
import setPaymentMethodOnCart from '@/queries/mutations/setPaymentMethodOnCart.gql.js';
import setCouponOnCart from '@/queries/mutations/setCouponOnCart.gql.js';
import removeCouponOnCart from '@/queries/mutations/removeCouponOnCart.gql.js';
import mergeCartsMutation from '~/queries/mutations/mergeCarts.graphql';
import { createEventData } from '@/utils/eventUtils';

export const state = () => ({
    cart: {},
    open: false,
});
export const mutations = {
    set_cart(state, value) {
        state.cart = value;
    },
    set_open(state, value) {
        state.open = value;
    },
};
export const actions = {
    async setCartId(context, id) {
        if (id) {
            return this.$storage.setCookie('cart_id', id, { maxAge: 2592000 });
        }
        const client = this.app.apolloProvider.defaultClient;

        const {
            data: { createEmptyCart: guestCartId },
        } = await client.mutate({ mutation: createEmptyCart });

        return this.$storage.setCookie('cart_id', guestCartId, { maxAge: 2592000 });
    },
    async getCart({ commit }) {
        const client = this.app.apolloProvider.defaultClient;
        const cartId = this.$storage.getCookie('cart_id');
        if (!cartId) {
            return;
        }
        const loggedIn = this.$storage.getCookie('apollo-token');
        const query = loggedIn ? getCustomerCart : getCart;

        const {
            data: { cart, customerCart },
        } = await client.query({
            query,
            variables: { id: cartId },
            fetchPolicy: 'no-cache',
        });
        commit('set_cart', cart || customerCart);
        this.$storage.setCookie('cart_qty', cart ? cart.total_quantity : customerCart.total_quantity);

        return { cart: cart || customerCart };
    },

    async activateInsurance({ commit, dispatch, rooState }, { items }) {
        const client = this.app.apolloProvider.defaultClient;
        let cartId = this.$storage.getCookie('cart_id');
        if (!cartId) {
            cartId = await dispatch('setCartId');
        }
        const {
            data: {
                addProductsToCart: { cart, user_errors },
            },
        } = await client.mutate({
            mutation: addToCartMutation,
            variables: { items, cartId },
        });

        await dispatch('getCart');

        return { cart, user_errors };
    },

    async addProduct({ commit, dispatch, rootState }, { items, product }) {
        const client = this.app.apolloProvider.defaultClient;
        let cartId = this.$storage.getCookie('cart_id');
        if (!cartId) {
            cartId = await dispatch('setCartId');
        }
        const {
            data: {
                addProductsToCart: { cart, user_errors },
            },
        } = await client.mutate({
            mutation: addToCartMutation,
            variables: { items, cartId },
        });

        if (this.$gtm) {
            setTimeout(() => {
                try {
                    this.$gtm.push({ ecommerce: null, items: null });
                    this.$gtm.push({
                        event: 'add_to_cart',
                        ecommerce: {
                            currency: 'EUR',
                            items: [
                                {
                                    item_id: product.sku,
                                    item_name: product.name,
                                    price: product.price_range.minimum_price.final_price.value,
                                    quantity: items[0].quantity,
                                },
                            ],
                        },
                        value: product.price_range.minimum_price.final_price.value,
                        items: [
                            {
                                id: product.sku,
                                google_business_vertical: 'retail',
                            },
                        ],
                    });
                } catch (e) {
                    console.error(e);
                }
            }, 2000);
        }

        const newCart = await dispatch('getCart');

        try {
            if (product.product) {
                product = product.product;
            }

            let userData = null;
            const fbc = this.$storage.getCookie('_fbc');
            if (fbc) {
                userData = {
                    fbc: fbc,
                };
            }

            const fbp = this.$storage.getCookie('_fbp');
            if (fbp) {
                userData = {
                    ...userData,
                    fbp: fbp,
                };
            }

            if (rootState.auth.loggedIn) {
                let user = rootState.auth.user;
                if (user) {
                    userData = {
                        ...userData,
                        em: rootState.auth.user.email,
                        fn: rootState.auth.user.firstname,
                        ln: rootState.auth.user.lastname,
                    };
                }
            }

            const eventData = createEventData(
                'AddToCart',
                window.location.href,
                {
                    contents: [
                        {
                            id: items[0].sku,
                            quantity: items[0].quantity,
                            item_price: product.price_range.minimum_price.final_price.value,
                        },
                    ],
                    content_type: 'product',
                    value: product.price_range.minimum_price.final_price.value,
                    currency: 'EUR',
                    content_ids: [items[0].sku],
                },
                userData,
            );

            await this.dispatch('eventTracking/sendEventData', { eventData });
        } catch (e) {
            console.error(e);
        }

        return { cart: newCart, user_errors };
    },
    async update({ commit, dispatch }, { uid, items }) {
        const client = this.app.apolloProvider.defaultClient;
        let cartId = this.$storage.getCookie('cart_id');
        if (!cartId) {
            cartId = await dispatch('setCartId');
        }

        const {
            data: {
                updateCartItems: { cart },
            },
        } = await client.mutate({
            mutation: updateCartMutation,
            variables: {
                cartId,
                items,
            },
        });

        await dispatch('getCart');
        return { cart };
    },
    async removeProduct({ commit, dispatch }, { uid, product }) {
        const client = this.app.apolloProvider.defaultClient;
        const {
            data: {
                removeItemFromCart: { cart },
            },
        } = await client.mutate({
            mutation,
            variables: {
                cartId: this.$storage.getCookie('cart_id'),
                cartItemId: uid,
            },
        });

        if (this.$gtm) {
            try {
                this.$gtm.push({ ecommerce: null });
                this.$gtm.push({
                    event: 'remove_from_cart',
                    ecommerce: {
                        currency: 'EUR',
                        items: [
                            {
                                item_id: product.sku,
                                item_name: product.name,
                                price: product.prices.price_including_tax.value,
                                quantity: product.quantity,
                            },
                        ],
                    },
                });
            } catch (e) {
                console.error(e);
            }
        }

        commit('set_cart', cart);

        this.$storage.setCookie('cart_qty', cart.total_quantity);

        return { cart };
    },
    async setGuestEmailOnCart({ commit, dispatch }, email) {
        const client = this.app.apolloProvider.defaultClient;
        const cartId = this.$storage.getCookie('cart_id');
        try {
            const {
                data: {
                    setGuestEmailOnCart: { cart },
                },
            } = await client.mutate({
                mutation: setGuestEmailOnCart,
                variables: { email, cartId },
            });

            commit('set_cart', cart);

            return { cart };
        } catch (e) {
            throw new Error(e.message);
        }
    },
    async setCouponOnCart({ commit, dispatch }, code) {
        const client = this.app.apolloProvider.defaultClient;
        const cartId = this.$storage.getCookie('cart_id');
        try {
            const {
                data: {
                    applyCouponToCart: { cart },
                },
            } = await client.mutate({
                mutation: setCouponOnCart,
                variables: { cartId, code },
            });

            commit('set_cart', cart);

            return { cart };
        } catch (e) {
            throw new Error(e.message);
        }
    },
    async removeCouponOnCart({ commit, dispatch }) {
        const client = this.app.apolloProvider.defaultClient;
        const cartId = this.$storage.getCookie('cart_id');
        try {
            const {
                data: {
                    removeCouponFromCart: { cart },
                },
            } = await client.mutate({
                mutation: removeCouponOnCart,
                variables: { cartId },
            });

            commit('set_cart', cart);

            return { cart };
        } catch (e) {
            throw new Error(e.message);
        }
    },
    async setShippingAddressOnCart({ commit, dispatch }, { shipping, billing }) {
        const client = this.app.apolloProvider.defaultClient;
        const cartId = this.$storage.getCookie('cart_id');
        let notes = {};
        const billingAddress = billing ? JSON.parse(JSON.stringify(billing)) : null;
        if (billingAddress) {
            const { vat, eInvoice, operator } = billingAddress;
            notes = {
                vat,
                eInvoice,
                operator,
            };
            delete billingAddress.vat;
            delete billingAddress.eInvoice;
            delete billingAddress.operator;
            delete billingAddress.email;
        }
        try {
            const {
                data: {
                    setShippingAddressesOnCart: { cart },
                },
            } = await client.mutate({
                mutation: setShippingAddressOnCart,
                variables: { address: [{ address: shipping }], cartId },
            });

            const {
                data: {
                    setBillingAddressOnCart: { cart: billingCart },
                },
            } = await client.mutate({
                mutation: setBillingAddressOnCart,
                variables: { address: { address: billingAddress || shipping }, cartId },
            });

            if (billingAddress) {
                const {
                    data: {
                        setAdditionalDataToCart: { cart },
                    },
                } = await client.mutate({
                    mutation: setAdditionalDataOnCart,
                    variables: { input: { cart_id: cartId, data: JSON.stringify(notes) } },
                });
            }

            commit('set_cart', billingCart);

            return { billingCart };
        } catch (e) {
            throw new Error(e.message);
        }
    },
    async setShippingMethodsOnCart({ commit, dispatch }, shippingMethods) {
        const client = this.app.apolloProvider.defaultClient;
        const cartId = this.$storage.getCookie('cart_id');
        try {
            const {
                data: {
                    setShippingMethodsOnCart: { cart },
                },
            } = await client.mutate({
                mutation: setShippingMethodsOnCart,
                variables: { shippingMethods, cartId },
            });

            commit('set_cart', cart);

            return { cart };
        } catch (e) {
            throw new Error(e.message);
        }
    },
    async setPaymentMethodOnCart({ commit, dispatch }, paymentMethod) {
        const client = this.app.apolloProvider.defaultClient;
        const cartId = this.$storage.getCookie('cart_id');

        let mutation = setPaymentMethodOnCart;
        let variables = { paymentMethod, cartId };

        if (paymentMethod.code.includes('klarna_') && this.$storage.getCookie('klarna_payment_session_token')) {
            mutation = setPaymentMethodOnCart;
            variables = {
                paymentMethod: {
                    code: paymentMethod.code,
                    klarna: {
                        authorization_token: (await this.dispatch('klarna/getKlarnaAuthorizationToken')) ?? 'place_holder',
                    },
                },
                cartId,
            };
        }

        try {
            const {
                data: {
                    setPaymentMethodOnCart: { cart },
                },
            } = await client.mutate({
                mutation: mutation,
                variables: variables,
            });

            commit('set_cart', cart);

            return { cart };
        } catch (e) {
            throw new Error(e.message);
        }
    },
    async mergeCarts({}, oldId) {
        if (oldId) {
            const client = this.app.apolloProvider.defaultClient;

            const newId = this.$storage.getCookie('cart_id');
            try {
                const data = await client.mutate({
                    mutation: mergeCartsMutation,
                    variables: {
                        source: oldId,
                        destination: newId,
                    },
                });
            } catch (e) {
                console.error(e);
            }
        }
    },
    openCart({ commit, dispatch }, value) {
        commit('set_open', value);
    },
};
export const getters = {
    open: (state) => state.open,
};
